



























































































.miniapp-popover {
  width: 188px !important;
  height: 264px !important;
  border-radius: 12px !important;
  padding: 0 !important;
  border: none !important;
  background: url("../../../assets/images/miniapp-msg-tips-bg.png") no-repeat !important;
  background-size: 188px 264px !important;
  box-shadow: none !important;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 65px 20px 18px;
    .title {
      width: 100%;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 17px;
      font-weight: 900;
      color: #435f6a;
      text-align: right;
      line-height: 17px;
      margin-bottom: 12px;
    }
    .tips {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      margin-bottom: 10px;
      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 1px;
        background: #dfdfdf;
      }
    }
    .qrcode {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";