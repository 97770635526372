







































































































































































































































































































































































































.custom-title {
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  line-height: 27px;
  margin-right: 53px;
  cursor: pointer;
  &.active {
    color: #171717;
  }
}

.game-list-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  .slide-container {
    display: flex;
    gap: 21px;
    transition: all 0.3s;
    .game-item {
      position: relative;
      flex-shrink: 0;
      width: 535px;
      height: 387px;
      border: 1px solid #e3e3e3;
      .swiper-container {
        width: 100%;
        height: 100%;
        --swiper-theme-color: #7175a1;
        .swiper-slide {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video {
            width: 100%;
            height: 100%;
            position: relative;
            .wiki-icon {
              position: absolute;
              display: inline-block;
              width: 60px;
              height: 60px;
              top: 121px;
              left: 236px;
              cursor: pointer;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .custom-swiper-pagination {
          position: absolute;
          bottom: 90px;
          width: 100%;
          z-index: 1;
          text-align: center;
          /deep/ .swiper-pagination-bullet {
            margin-right: 4px;
          }
        }
      }
      .game-box {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 87px;
        padding: 11px 12px;
        display: flex;
        backdrop-filter: blur(20px);
        background-color: rgba(0, 0, 0, 0.2);
        .game-icon {
          flex-shrink: 0;
          width: 64px;
          height: 64px;
          margin-right: 10px;
          border-radius: 4px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            transition: all 0.2s;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        .game-info {
          flex: 1;
          width: 100%;
          .game-name {
            height: 14px;
            display: flex;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;
            .name {
              flex: 1;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              line-height: 21px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .time {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
            }
          }
          .game-desc {
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.9);
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .slide-handler {
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 387px;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    .el-icon {
      font-size: 23px;
      color: #fff;
    }

    &.prev {
      top: 0;
      left: 0;
    }
    &.next {
      top: 0;
      right: 0;
    }
  }
}

.timeline-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  &.has-slide {
    padding: 0 62px;
    .timeline-slide-btn {
      display: flex;
    }
  }
  .timeline-slide-btn {
    display: none;
    position: absolute;
    width: 32px;
    height: 155px;
    background: #787980;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: #86878f;
    }
  }
  .prev-btn {
    left: 0;
    top: 25px;
  }
  .next-btn {
    right: 0;
    top: 25px;
  }
}
.game-list-timeline {
  --gap-width: 21px;
  display: flex;
  gap: var(--gap-width);
  transition: all 0.3s;
  .item {
    flex-shrink: 0;
    .top-box {
      text-align: center;
      .date {
        font-size: 11px;
        color: #333;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .line {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          width: calc(100% + var(--gap-width));
          height: 1px;
          background: #dddddd;
          position: absolute;
          left: 0;
          top: 10px;
        }
        .round {
          width: 20px;
          height: 20px;
          background: #d8d8d8;
          border-radius: 50%;
          border: 6px solid #fff;
          position: relative;
          z-index: 1;
        }
      }
    }

    .bottom-box {
      padding: 7px 8px;
      border: 1px solid transparent;
      &.is-select {
        background: rgba(0, 175, 232, 0.1);
        border-radius: 4px;
        border: 1px solid #00afe8;
      }
      .img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 90px;
          height: 90px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .text-box {
        margin-top: 12px;
        width: 90px;
        overflow: hidden;
        margin-bottom: 7px;
        .title {
          .ellipsis;
          width: 100%;
          font-size: 14px;
          color: #212121;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";