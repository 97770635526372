.item-group {
  --gap-width: 39px;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
  overflow: hidden;
}
.item-group .item .top-box {
  text-align: center;
}
.item-group .item .top-box .date {
  font-size: 11px;
  color: #333;
  font-weight: 500;
  margin-bottom: 3px;
}
.item-group .item .top-box .line {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-group .item .top-box .line:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 10px;
}
.item-group .item .top-box .line .round {
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 6px solid #fff;
  position: relative;
  z-index: 1;
}
.item-group .item .bottom-box {
  padding: 7px 0;
}
.item-group .item .bottom-box .img {
  width: 90px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}
.item-group .item .bottom-box .img img {
  width: 90px;
  height: 90px;
  transition: all 0.2s;
}
.item-group .item .bottom-box .img img:hover {
  transform: scale(1.05);
}
.item-group .item .bottom-box .text-box {
  margin-top: 12px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 7px;
}
.item-group .item .bottom-box .text-box .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  text-align: center;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
