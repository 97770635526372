 /deep/ .video-player {
  background: transparent;
  box-shadow: none;
}
 /deep/ .video-player .el-dialog__header .el-dialog__headerbtn {
  top: 10px;
  right: 5px;
}
 /deep/ .video-player .el-dialog__header .el-dialog__headerbtn .el-icon-close {
  font-size: 24px;
  color: #fff;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
