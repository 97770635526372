

















































.friend-links {
  width: 100%;
  .link {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    line-height: 21px;
    margin: 0 28px;
    &:first-child {
      margin-left: 0;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";