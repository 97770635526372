



































































.item-group {
  display: grid;
  grid-gap: 32px 16px;
  grid-template-columns: repeat(6, 1fr);

  &.item-group-8 {
    grid-template-columns: repeat(8, 1fr);
  }

  .item {
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .img {
      width: 100%;
      height: 260px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      line-height: 1;
      height: 44px;
      padding-top: 10px;

      .title {
        .ellipsis;
        width: 100%;
        color: #212121;
        font-weight: 500;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }
      .tag {
        font-size: 12px;
        color: #666;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";