.item-group {
  display: grid;
  grid-gap: 32px 16px;
  grid-template-columns: repeat(6, 1fr);
}
.item-group.item-group-8 {
  grid-template-columns: repeat(8, 1fr);
}
.item-group .item {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.item-group .item .img {
  width: 100%;
  height: 260px;
  border-radius: 4px;
  overflow: hidden;
}
.item-group .item .img img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  transition: all 0.2s;
}
.item-group .item .img img:hover {
  transform: scale(1.05);
}
.item-group .item .text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1;
  height: 44px;
  padding-top: 10px;
}
.item-group .item .text-box .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
  color: #212121;
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}
.item-group .item .text-box .tag {
  font-size: 12px;
  color: #666;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
