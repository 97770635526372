









































.item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 84px;
  overflow: hidden;
  .cover {
    height: 84px;
    width: 140px;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .item-r {
    width: calc(100% - 118px);
    padding: 0 8px 4px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 14px;
      font-weight: 500;
      color: #212121;
      line-height: 20px;
      overflow: hidden;
      height: 40px;
      text-align: justify;
    }

    .desc {
      font-size: 12px;
      color: #999;
      line-height: 16px;
      height: 32px;
      overflow: hidden;
      display: none;
    }
    .bottom-row {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #999;

      .user {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
          border: 1px solid #909090;
          margin-right: 8px;
          border-radius: 50%;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";