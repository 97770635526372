






























































































































































































.index-page {
  background: #fff;
  padding: 20px;
  border-radius: 6px;

  .l-wrapper {
    width: 100%;
    min-height: 500px;
  }

  .r-wrapper {
    width: 630px;
    min-height: 500px;
    display: none;
  }
}

.ad-container {
  .item {
    width: 100%;
    height: 90px;
    display: block;

    & + .item {
      margin-top: 12px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.wap-index-page {
  .l-wrapper {
    width: 100%;
  }

  .r-wrapper {
    display: none;
  }
}

.pc-index-page {
  margin: auto;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}

.manual-container {
  .item {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    padding: 0 12px;
    font-size: 13px;
    color: #666;

    & + .item {
      margin-top: 8px;
    }
  }
}

.app-bar {
  .app-bar-content {
    background: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }
}

.wap-footer {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  a {
    color: inherit;
  }
}
.news-video-box {
  display: flex;
  align-items: stretch;
  .row-item-1 {
    flex: 1;
    overflow: hidden;
  }
  .row-item-2 {
    width: 380px;
    padding-left: 28px;
    margin-left: 20px;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: calc(100% - 90px);
      width: 1px;
      top: 44px;
      left: 0;
      background: #e3e3e3;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";