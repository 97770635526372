



















/deep/ .video-player {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    .el-dialog__headerbtn {
      top: 10px;
      right: 5px;
      .el-icon-close {
        font-size: 24px;
        color: #fff;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";