.link-group {
  display: flex;
}
.link-group a {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cecece;
  font-size: 14px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
