.custom-title {
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  line-height: 27px;
  margin-right: 53px;
  cursor: pointer;
}
.custom-title.active {
  color: #171717;
}
.game-list-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.game-list-container .slide-container {
  display: flex;
  gap: 21px;
  transition: all 0.3s;
}
.game-list-container .slide-container .game-item {
  position: relative;
  flex-shrink: 0;
  width: 535px;
  height: 387px;
  border: 1px solid #e3e3e3;
}
.game-list-container .slide-container .game-item .swiper-container {
  width: 100%;
  height: 100%;
  --swiper-theme-color: #7175a1;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video {
  width: 100%;
  height: 100%;
  position: relative;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video .wiki-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  top: 121px;
  left: 236px;
  cursor: pointer;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-list-container .slide-container .game-item .swiper-container .custom-swiper-pagination {
  position: absolute;
  bottom: 90px;
  width: 100%;
  z-index: 1;
  text-align: center;
}
.game-list-container .slide-container .game-item .swiper-container .custom-swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-right: 4px;
}
.game-list-container .slide-container .game-item .game-box {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 87px;
  padding: 11px 12px;
  display: flex;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.2);
}
.game-list-container .slide-container .game-item .game-box .game-icon {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-container .slide-container .game-item .game-box .game-icon img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: all 0.2s;
}
.game-list-container .slide-container .game-item .game-box .game-icon img:hover {
  transform: scale(1.05);
}
.game-list-container .slide-container .game-item .game-box .game-info {
  flex: 1;
  width: 100%;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name {
  height: 14px;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name .name {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name .time {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-desc {
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-list-container .slide-handler {
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 387px;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.game-list-container .slide-handler .el-icon {
  font-size: 23px;
  color: #fff;
}
.game-list-container .slide-handler.prev {
  top: 0;
  left: 0;
}
.game-list-container .slide-handler.next {
  top: 0;
  right: 0;
}
.timeline-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.timeline-container.has-slide {
  padding: 0 62px;
}
.timeline-container.has-slide .timeline-slide-btn {
  display: flex;
}
.timeline-container .timeline-slide-btn {
  display: none;
  position: absolute;
  width: 32px;
  height: 155px;
  background: #787980;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
.timeline-container .timeline-slide-btn:hover {
  background: #86878f;
}
.timeline-container .prev-btn {
  left: 0;
  top: 25px;
}
.timeline-container .next-btn {
  right: 0;
  top: 25px;
}
.game-list-timeline {
  --gap-width: 21px;
  display: flex;
  gap: var(--gap-width);
  transition: all 0.3s;
}
.game-list-timeline .item {
  flex-shrink: 0;
}
.game-list-timeline .item .top-box {
  text-align: center;
}
.game-list-timeline .item .top-box .date {
  font-size: 11px;
  color: #333;
  font-weight: 500;
  margin-bottom: 3px;
}
.game-list-timeline .item .top-box .line {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.game-list-timeline .item .top-box .line:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 10px;
}
.game-list-timeline .item .top-box .line .round {
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 6px solid #fff;
  position: relative;
  z-index: 1;
}
.game-list-timeline .item .bottom-box {
  padding: 7px 8px;
  border: 1px solid transparent;
}
.game-list-timeline .item .bottom-box.is-select {
  background: rgba(0, 175, 232, 0.1);
  border-radius: 4px;
  border: 1px solid #00afe8;
}
.game-list-timeline .item .bottom-box .img {
  width: 90px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-timeline .item .bottom-box .img img {
  width: 90px;
  height: 90px;
  transition: all 0.2s;
}
.game-list-timeline .item .bottom-box .img img:hover {
  transform: scale(1.05);
}
.game-list-timeline .item .bottom-box .text-box {
  margin-top: 12px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 7px;
}
.game-list-timeline .item .bottom-box .text-box .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  text-align: center;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
