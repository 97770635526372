.module-container[data-v-390ad8e5] {
  margin-bottom: 54px;
}
.module-title[data-v-390ad8e5] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 30px;
  margin-bottom: 14px;
}
.module-title .t-l[data-v-390ad8e5] {
  font-size: 16px;
  color: #171717;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
}
.module-title .t-r[data-v-390ad8e5] .more-btn {
  color: #3d3d3d;
  font-size: 13px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
}
.module-title .t-r[data-v-390ad8e5] .more-btn .el-icon {
  border: 1px solid #3d3d3d;
  border-radius: 4px;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: middle;
  margin-left: 2px;
}
.module-content[data-v-390ad8e5] {
  overflow-x: auto;
  overflow-y: hidden;
}
.ellipsis[data-v-390ad8e5] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-390ad8e5] {
  *zoom: 1;
}
.clearfix[data-v-390ad8e5]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-390ad8e5] {
  float: left;
}
.fr[data-v-390ad8e5] {
  float: right;
}

.item-group[data-v-bae4afa2] {
  display: grid;
  grid-gap: 32px 16px;
  grid-template-columns: repeat(6, 1fr);
}
.item-group.item-group-8[data-v-bae4afa2] {
  grid-template-columns: repeat(8, 1fr);
}
.item-group .item[data-v-bae4afa2] {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.item-group .item .img[data-v-bae4afa2] {
  width: 100%;
  height: 260px;
  border-radius: 4px;
  overflow: hidden;
}
.item-group .item .img img[data-v-bae4afa2] {
  width: 100%;
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.item-group .item .img img[data-v-bae4afa2]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.item-group .item .text-box[data-v-bae4afa2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 1;
  height: 44px;
  padding-top: 10px;
}
.item-group .item .text-box .title[data-v-bae4afa2] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: #212121;
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}
.item-group .item .text-box .tag[data-v-bae4afa2] {
  font-size: 12px;
  color: #666;
}
.ellipsis[data-v-bae4afa2] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-bae4afa2] {
  *zoom: 1;
}
.clearfix[data-v-bae4afa2]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-bae4afa2] {
  float: left;
}
.fr[data-v-bae4afa2] {
  float: right;
}

.item-group[data-v-5125462c] {
  --gap-width: 39px;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
  overflow: hidden;
}
.item-group .item .top-box[data-v-5125462c] {
  text-align: center;
}
.item-group .item .top-box .date[data-v-5125462c] {
  font-size: 11px;
  color: #333;
  font-weight: 500;
  margin-bottom: 3px;
}
.item-group .item .top-box .line[data-v-5125462c] {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.item-group .item .top-box .line[data-v-5125462c]:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 10px;
}
.item-group .item .top-box .line .round[data-v-5125462c] {
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 6px solid #fff;
  position: relative;
  z-index: 1;
}
.item-group .item .bottom-box[data-v-5125462c] {
  padding: 7px 0;
}
.item-group .item .bottom-box .img[data-v-5125462c] {
  width: 90px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}
.item-group .item .bottom-box .img img[data-v-5125462c] {
  width: 90px;
  height: 90px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.item-group .item .bottom-box .img img[data-v-5125462c]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.item-group .item .bottom-box .text-box[data-v-5125462c] {
  margin-top: 12px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 7px;
}
.item-group .item .bottom-box .text-box .title[data-v-5125462c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  text-align: center;
}
.ellipsis[data-v-5125462c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-5125462c] {
  *zoom: 1;
}
.clearfix[data-v-5125462c]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-5125462c] {
  float: left;
}
.fr[data-v-5125462c] {
  float: right;
}

.loading-text[data-v-489d978c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px 0;
  color: #999;
}
.loading-text i[data-v-489d978c] {
  margin-right: 10px;
}
.ellipsis[data-v-489d978c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-489d978c] {
  *zoom: 1;
}
.clearfix[data-v-489d978c]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-489d978c] {
  float: left;
}
.fr[data-v-489d978c] {
  float: right;
}

.item[data-v-3757afe6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  height: 84px;
  overflow: hidden;
}
.item .cover[data-v-3757afe6] {
  height: 84px;
  width: 140px;
  border-radius: 4px;
  overflow: hidden;
}
.item .cover img[data-v-3757afe6] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.item .cover img[data-v-3757afe6]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.item .item-r[data-v-3757afe6] {
  width: calc(100% - 118px);
  padding: 0 8px 4px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item .item-r .title[data-v-3757afe6] {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 20px;
  overflow: hidden;
  height: 40px;
  text-align: justify;
}
.item .item-r .desc[data-v-3757afe6] {
  font-size: 12px;
  color: #999;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
  display: none;
}
.item .item-r .bottom-row[data-v-3757afe6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  color: #999;
}
.item .item-r .bottom-row .user[data-v-3757afe6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.item .item-r .bottom-row .user img[data-v-3757afe6] {
  width: 16px;
  height: 16px;
  -o-object-fit: cover;
     object-fit: cover;
  border: 1px solid #909090;
  margin-right: 8px;
  border-radius: 50%;
}
.ellipsis[data-v-3757afe6] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-3757afe6] {
  *zoom: 1;
}
.clearfix[data-v-3757afe6]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-3757afe6] {
  float: left;
}
.fr[data-v-3757afe6] {
  float: right;
}

.list-box[data-v-2aea803a] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}
.ellipsis[data-v-2aea803a] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-2aea803a] {
  *zoom: 1;
}
.clearfix[data-v-2aea803a]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-2aea803a] {
  float: left;
}
.fr[data-v-2aea803a] {
  float: right;
}


[data-v-8e2cceac] .video-player {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
[data-v-8e2cceac] .video-player .el-dialog__header .el-dialog__headerbtn {
  top: 10px;
  right: 5px;
}
[data-v-8e2cceac] .video-player .el-dialog__header .el-dialog__headerbtn .el-icon-close {
  font-size: 24px;
  color: #fff;
}
.ellipsis[data-v-8e2cceac] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-8e2cceac] {
  *zoom: 1;
}
.clearfix[data-v-8e2cceac]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-8e2cceac] {
  float: left;
}
.fr[data-v-8e2cceac] {
  float: right;
}

.custom-title[data-v-7888da5e] {
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  line-height: 27px;
  margin-right: 53px;
  cursor: pointer;
}
.custom-title.active[data-v-7888da5e] {
  color: #171717;
}
.game-list-container[data-v-7888da5e] {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.game-list-container .slide-container[data-v-7888da5e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 21px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.game-list-container .slide-container .game-item[data-v-7888da5e] {
  position: relative;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 535px;
  height: 387px;
  border: 1px solid #e3e3e3;
}
.game-list-container .slide-container .game-item .swiper-container[data-v-7888da5e] {
  width: 100%;
  height: 100%;
  --swiper-theme-color: #7175a1;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide[data-v-7888da5e] {
  width: 100%;
  height: 100%;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide img[data-v-7888da5e] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video[data-v-7888da5e] {
  width: 100%;
  height: 100%;
  position: relative;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video .wiki-icon[data-v-7888da5e] {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  top: 121px;
  left: 236px;
  cursor: pointer;
}
.game-list-container .slide-container .game-item .swiper-container .swiper-slide .video img[data-v-7888da5e] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-list-container .slide-container .game-item .swiper-container .custom-swiper-pagination[data-v-7888da5e] {
  position: absolute;
  bottom: 90px;
  width: 100%;
  z-index: 1;
  text-align: center;
}
.game-list-container .slide-container .game-item .swiper-container .custom-swiper-pagination[data-v-7888da5e] .swiper-pagination-bullet {
  margin-right: 4px;
}
.game-list-container .slide-container .game-item .game-box[data-v-7888da5e] {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 87px;
  padding: 11px 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.2);
}
.game-list-container .slide-container .game-item .game-box .game-icon[data-v-7888da5e] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-container .slide-container .game-item .game-box .game-icon img[data-v-7888da5e] {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-list-container .slide-container .game-item .game-box .game-icon img[data-v-7888da5e]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-list-container .slide-container .game-item .game-box .game-info[data-v-7888da5e] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name[data-v-7888da5e] {
  height: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name .name[data-v-7888da5e] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-name .time[data-v-7888da5e] {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.game-list-container .slide-container .game-item .game-box .game-info .game-desc[data-v-7888da5e] {
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-list-container .slide-handler[data-v-7888da5e] {
  position: absolute;
  z-index: 11;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
  height: 387px;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.game-list-container .slide-handler .el-icon[data-v-7888da5e] {
  font-size: 23px;
  color: #fff;
}
.game-list-container .slide-handler.prev[data-v-7888da5e] {
  top: 0;
  left: 0;
}
.game-list-container .slide-handler.next[data-v-7888da5e] {
  top: 0;
  right: 0;
}
.timeline-container[data-v-7888da5e] {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.timeline-container.has-slide[data-v-7888da5e] {
  padding: 0 62px;
}
.timeline-container.has-slide .timeline-slide-btn[data-v-7888da5e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.timeline-container .timeline-slide-btn[data-v-7888da5e] {
  display: none;
  position: absolute;
  width: 32px;
  height: 155px;
  background: #787980;
  border-radius: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.timeline-container .timeline-slide-btn[data-v-7888da5e]:hover {
  background: #86878f;
}
.timeline-container .prev-btn[data-v-7888da5e] {
  left: 0;
  top: 25px;
}
.timeline-container .next-btn[data-v-7888da5e] {
  right: 0;
  top: 25px;
}
.game-list-timeline[data-v-7888da5e] {
  --gap-width: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: var(--gap-width);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.game-list-timeline .item[data-v-7888da5e] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.game-list-timeline .item .top-box[data-v-7888da5e] {
  text-align: center;
}
.game-list-timeline .item .top-box .date[data-v-7888da5e] {
  font-size: 11px;
  color: #333;
  font-weight: 500;
  margin-bottom: 3px;
}
.game-list-timeline .item .top-box .line[data-v-7888da5e] {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.game-list-timeline .item .top-box .line[data-v-7888da5e]:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 10px;
}
.game-list-timeline .item .top-box .line .round[data-v-7888da5e] {
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 6px solid #fff;
  position: relative;
  z-index: 1;
}
.game-list-timeline .item .bottom-box[data-v-7888da5e] {
  padding: 7px 8px;
  border: 1px solid transparent;
}
.game-list-timeline .item .bottom-box.is-select[data-v-7888da5e] {
  background: rgba(0, 175, 232, 0.1);
  border-radius: 4px;
  border: 1px solid #00afe8;
}
.game-list-timeline .item .bottom-box .img[data-v-7888da5e] {
  width: 90px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-timeline .item .bottom-box .img img[data-v-7888da5e] {
  width: 90px;
  height: 90px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-list-timeline .item .bottom-box .img img[data-v-7888da5e]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-list-timeline .item .bottom-box .text-box[data-v-7888da5e] {
  margin-top: 12px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 7px;
}
.game-list-timeline .item .bottom-box .text-box .title[data-v-7888da5e] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  text-align: center;
}
.ellipsis[data-v-7888da5e] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-7888da5e] {
  *zoom: 1;
}
.clearfix[data-v-7888da5e]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-7888da5e] {
  float: left;
}
.fr[data-v-7888da5e] {
  float: right;
}

.content-list[data-v-3ab86c99] {
  display: grid;
  grid-gap: 28px 28px;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}
.content-list .item[data-v-3ab86c99] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  overflow: hidden;
}
.content-list .item .img[data-v-3ab86c99] {
  width: 68px;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
}
.content-list .item .img img[data-v-3ab86c99] {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.content-list .item .img img[data-v-3ab86c99]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.content-list .item .r-wrapper[data-v-3ab86c99] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  padding: 2px 0;
  padding-left: 13px;
}
.content-list .item .r-wrapper .row1[data-v-3ab86c99] {
  font-size: 12px;
  color: #999;
}
.content-list .item .r-wrapper .row2[data-v-3ab86c99] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #212121;
  font-size: 14px;
  margin-bottom: 4px;
}
.content-list .item .r-wrapper .row3[data-v-3ab86c99] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
  font-size: 12px;
}
.ellipsis[data-v-3ab86c99] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-3ab86c99] {
  *zoom: 1;
}
.clearfix[data-v-3ab86c99]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-3ab86c99] {
  float: left;
}
.fr[data-v-3ab86c99] {
  float: right;
}

.miniapp-popover {
  width: 188px !important;
  height: 264px !important;
  border-radius: 12px !important;
  padding: 0 !important;
  border: none !important;
  background: url(../img/miniapp-msg-tips-bg.72011546.png) no-repeat !important;
  background-size: 188px 264px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.miniapp-popover .container {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 65px 20px 18px;
}
.miniapp-popover .container .title {
  width: 100%;
  padding-right: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
  font-size: 17px;
  font-weight: 900;
  color: #435f6a;
  text-align: right;
  line-height: 17px;
  margin-bottom: 12px;
}
.miniapp-popover .container .tips {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
}
.miniapp-popover .container .tips::before,
.miniapp-popover .container .tips::after {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  background: #dfdfdf;
}
.miniapp-popover .container .qrcode {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.miniapp-popover .container .qrcode img {
  width: 100px;
  height: 100px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.link-group[data-v-12d25950] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.link-group a[data-v-12d25950] {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cecece;
  font-size: 14px;
  color: #666666;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 16px;
}
.ellipsis[data-v-12d25950] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-12d25950] {
  *zoom: 1;
}
.clearfix[data-v-12d25950]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-12d25950] {
  float: left;
}
.fr[data-v-12d25950] {
  float: right;
}

.fade-enter-active[data-v-5bdbeece],
.fade-leave-active[data-v-5bdbeece] {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
.fade-enter-from[data-v-5bdbeece],
.fade-leave-to[data-v-5bdbeece] {
  opacity: 0;
}
.game-recommend[data-v-5bdbeece] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.game-recommend .game-video[data-v-5bdbeece] {
  width: 670px;
  height: 380px;
  position: relative;
  background: url(../img/index-recommend-bg.6c7973a3.png) no-repeat;
  background-size: cover;
}
.game-recommend .game-video:hover .select-btn[data-v-5bdbeece] {
  display: block;
}
.game-recommend .game-video .video[data-v-5bdbeece] {
  width: 670px;
  height: 380px;
}
.game-recommend .game-video .video video[data-v-5bdbeece] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-recommend .game-video .play-icon[data-v-5bdbeece] {
  display: inline-block;
  width: 57px;
  height: 57px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.game-recommend .game-video .select-btn[data-v-5bdbeece] {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-recommend .game-video .select-btn[data-v-5bdbeece]:hover {
  opacity: 0.8;
  -webkit-transform: scale(1.01);
      -ms-transform: scale(1.01);
          transform: scale(1.01);
}
.game-recommend .game-video .select-btn.prev[data-v-5bdbeece] {
  top: 160px;
  left: 20px;
}
.game-recommend .game-video .select-btn.next[data-v-5bdbeece] {
  top: 160px;
  right: 20px;
}
.game-recommend .recommend-list[data-v-5bdbeece] {
  width: 550px;
  height: 340px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  background: url(../img/index-recommend-bg.6c7973a3.png) no-repeat;
  background-size: cover;
}
.game-recommend .recommend-list .recommend-item[data-v-5bdbeece] {
  width: 100%;
  height: 160px;
}
.game-recommend .recommend-list .recommend-item.active .cover[data-v-5bdbeece] {
  border: 2px solid #ffffff;
}
.game-recommend .recommend-list .recommend-item .cover[data-v-5bdbeece] {
  width: 167px;
  height: 94px;
  border-radius: 6px;
  margin-bottom: 7px;
  cursor: pointer;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .cover img[data-v-5bdbeece] {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-recommend .recommend-list .recommend-item .cover img[data-v-5bdbeece]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-recommend .recommend-list .recommend-item .title[data-v-5bdbeece] {
  width: 167px;
  height: 36px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info[data-v-5bdbeece] {
  width: 167px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #bfbfbf;
  line-height: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.game-recommend .recommend-list .recommend-item .info .game-name[data-v-5bdbeece] {
  color: #bfbfbf;
}
.game-recommend .recommend-list .recommend-item .info span[data-v-5bdbeece] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info span[data-v-5bdbeece]:first-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.game-recommend .recommend-list .recommend-item .info span .wiki-icon[data-v-5bdbeece] {
  display: inline-block;
  width: 11px;
  height: 10px;
  margin-right: 4px;
}
.ellipsis[data-v-5bdbeece] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-5bdbeece] {
  *zoom: 1;
}
.clearfix[data-v-5bdbeece]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-5bdbeece] {
  float: left;
}
.fr[data-v-5bdbeece] {
  float: right;
}

.friend-links[data-v-11a9ea18] {
  width: 100%;
}
.friend-links .link[data-v-11a9ea18] {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 21px;
  margin: 0 28px;
}
.friend-links .link[data-v-11a9ea18]:first-child {
  margin-left: 0;
}
.ellipsis[data-v-11a9ea18] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-11a9ea18] {
  *zoom: 1;
}
.clearfix[data-v-11a9ea18]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-11a9ea18] {
  float: left;
}
.fr[data-v-11a9ea18] {
  float: right;
}

.index-page[data-v-2cc09567] {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
}
.index-page .l-wrapper[data-v-2cc09567] {
  width: 100%;
  min-height: 500px;
}
.index-page .r-wrapper[data-v-2cc09567] {
  width: 630px;
  min-height: 500px;
  display: none;
}
.ad-container .item[data-v-2cc09567] {
  width: 100%;
  height: 90px;
  display: block;
}
.ad-container .item + .item[data-v-2cc09567] {
  margin-top: 12px;
}
.ad-container .item img[data-v-2cc09567] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
}
.wap-index-page .l-wrapper[data-v-2cc09567] {
  width: 100%;
}
.wap-index-page .r-wrapper[data-v-2cc09567] {
  display: none;
}
.pc-index-page[data-v-2cc09567] {
  margin: auto;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}
.manual-container .item[data-v-2cc09567] {
  height: 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #f9f9f9;
  padding: 0 12px;
  font-size: 13px;
  color: #666;
}
.manual-container .item + .item[data-v-2cc09567] {
  margin-top: 8px;
}
.app-bar .app-bar-content[data-v-2cc09567] {
  background: #404040;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 12px;
}
.wap-footer[data-v-2cc09567] {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
.wap-footer a[data-v-2cc09567] {
  color: inherit;
}
.news-video-box[data-v-2cc09567] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.news-video-box .row-item-1[data-v-2cc09567] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}
.news-video-box .row-item-2[data-v-2cc09567] {
  width: 380px;
  padding-left: 28px;
  margin-left: 20px;
  overflow: hidden;
  position: relative;
}
.news-video-box .row-item-2[data-v-2cc09567]:before {
  content: "";
  position: absolute;
  height: calc(100% - 90px);
  width: 1px;
  top: 44px;
  left: 0;
  background: #e3e3e3;
}
.ellipsis[data-v-2cc09567] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-2cc09567] {
  *zoom: 1;
}
.clearfix[data-v-2cc09567]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-2cc09567] {
  float: left;
}
.fr[data-v-2cc09567] {
  float: right;
}


/*# sourceMappingURL=chunk-47c42105.8c954e9f.css.map*/