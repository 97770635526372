.content-list {
  display: grid;
  grid-gap: 28px 28px;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}
.content-list .item {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}
.content-list .item .img {
  width: 68px;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
}
.content-list .item .img img {
  width: 100%;
  height: 100%;
  transition: all 0.2s;
}
.content-list .item .img img:hover {
  transform: scale(1.05);
}
.content-list .item .r-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  padding: 2px 0;
  padding-left: 13px;
}
.content-list .item .r-wrapper .row1 {
  font-size: 12px;
  color: #999;
}
.content-list .item .r-wrapper .row2 {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #212121;
  font-size: 14px;
  margin-bottom: 4px;
}
.content-list .item .r-wrapper .row3 {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
