.friend-links {
  width: 100%;
}
.friend-links .link {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 21px;
  margin: 0 28px;
}
.friend-links .link:first-child {
  margin-left: 0;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
