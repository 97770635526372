













































































.content-list {
  display: grid;
  grid-gap: 28px 28px;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
  .item {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    .img {
      width: 68px;
      height: 68px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .r-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      overflow: hidden;
      padding: 2px 0;
      padding-left: 13px;
      .row1 {
        font-size: 12px;
        color: #999;
      }
      .row2 {
        .ellipsis;
        color: #212121;
        font-size: 14px;
        margin-bottom: 4px;
      }
      .row3 {
        .ellipsis;
        color: #666;
        font-size: 12px;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";