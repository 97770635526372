























































































































































.link-group {
  display: flex;
  a {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #cecece;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";