






















































































.item-group {
  --gap-width: 39px;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
  overflow: hidden;
  .item {
    .top-box {
      text-align: center;
      .date {
        font-size: 11px;
        color: #333;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .line {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          width: calc(100% + var(--gap-width));
          height: 1px;
          background: #dddddd;
          position: absolute;
          left: 0;
          top: 10px;
        }
        .round {
          width: 20px;
          height: 20px;
          background: #d8d8d8;
          border-radius: 50%;
          border: 6px solid #fff;
          position: relative;
          z-index: 1;
        }
      }
    }

    .bottom-box {
      padding: 7px 0;
      .img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 90px;
          height: 90px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .text-box {
        margin-top: 12px;
        width: 90px;
        overflow: hidden;
        margin-bottom: 7px;
        .title {
          .ellipsis;
          width: 100%;
          font-size: 14px;
          color: #212121;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";