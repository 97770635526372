.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.game-recommend {
  width: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.game-recommend .game-video {
  width: 670px;
  height: 380px;
  position: relative;
  background: url(../../../assets//images/index-recommend-bg.png) no-repeat;
  background-size: cover;
}
.game-recommend .game-video:hover .select-btn {
  display: block;
}
.game-recommend .game-video .video {
  width: 670px;
  height: 380px;
}
.game-recommend .game-video .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-recommend .game-video .play-icon {
  display: inline-block;
  width: 57px;
  height: 57px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.game-recommend .game-video .select-btn {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
.game-recommend .game-video .select-btn:hover {
  opacity: 0.8;
  transform: scale(1.01);
}
.game-recommend .game-video .select-btn.prev {
  top: 160px;
  left: 20px;
}
.game-recommend .game-video .select-btn.next {
  top: 160px;
  right: 20px;
}
.game-recommend .recommend-list {
  width: 550px;
  height: 340px;
  box-sizing: content-box;
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  background: url(../../../assets//images/index-recommend-bg.png) no-repeat;
  background-size: cover;
}
.game-recommend .recommend-list .recommend-item {
  width: 100%;
  height: 160px;
}
.game-recommend .recommend-list .recommend-item.active .cover {
  border: 2px solid #ffffff;
}
.game-recommend .recommend-list .recommend-item .cover {
  width: 167px;
  height: 94px;
  border-radius: 6px;
  margin-bottom: 7px;
  cursor: pointer;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .cover img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  transition: all 0.2s;
}
.game-recommend .recommend-list .recommend-item .cover img:hover {
  transform: scale(1.05);
}
.game-recommend .recommend-list .recommend-item .title {
  width: 167px;
  height: 36px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info {
  width: 167px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #bfbfbf;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
}
.game-recommend .recommend-list .recommend-item .info .game-name {
  color: #bfbfbf;
}
.game-recommend .recommend-list .recommend-item .info span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info span:first-child {
  flex: 1;
}
.game-recommend .recommend-list .recommend-item .info span .wiki-icon {
  display: inline-block;
  width: 11px;
  height: 10px;
  margin-right: 4px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
