































































































.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: #999;

  i {
    margin-right: 10px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";