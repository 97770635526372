.module-container {
  margin-bottom: 54px;
}
.module-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 14px;
}
.module-title .t-l {
  font-size: 16px;
  color: #171717;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.module-title .t-r /deep/ .more-btn {
  color: #3d3d3d;
  font-size: 13px;
  display: flex;
  align-items: center;
  line-height: 1;
}
.module-title .t-r /deep/ .more-btn .el-icon {
  border: 1px solid #3d3d3d;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-left: 2px;
}
.module-content {
  overflow-x: auto;
  overflow-y: hidden;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
