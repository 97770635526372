.item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 84px;
  overflow: hidden;
}
.item .cover {
  height: 84px;
  width: 140px;
  border-radius: 4px;
  overflow: hidden;
}
.item .cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s;
}
.item .cover img:hover {
  transform: scale(1.05);
}
.item .item-r {
  width: calc(100% - 118px);
  padding: 0 8px 4px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item .item-r .title {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 20px;
  overflow: hidden;
  height: 40px;
  text-align: justify;
}
.item .item-r .desc {
  font-size: 12px;
  color: #999;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
  display: none;
}
.item .item-r .bottom-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}
.item .item-r .bottom-row .user {
  display: flex;
  align-items: center;
}
.item .item-r .bottom-row .user img {
  width: 16px;
  height: 16px;
  object-fit: cover;
  border: 1px solid #909090;
  margin-right: 8px;
  border-radius: 50%;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
